import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Layout from "../../components/layout"
import { graphql } from "gatsby"

const SearchPage = ({ location, data }) => {
  const [word, setWord] = useState("")
  const [siteData, setSiteData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const creatureData = data?.allSanityCreature?.nodes
  const spells = data?.allSanityHerospells?.edges[0]?.node
  const spellSlugData = [
    ...spells?.arcanaspells,
    ...spells?.combatspells,
    ...spells?.rangedspells,
    ...spells?.skillspells,
  ]
  const classSpells = data?.allSanityClass?.edges
  // console.log(classSpells, "data")

  useEffect(() => {
    handleApi()
  }, [])

  async function handleApi() {
    const res = await fetch(
      "https://tijfu5an.api.sanity.io/v2021-06-07/data/query/production?query=*[_type==%27class%27||_type==%27herospells%27||%20_type==%27denizen%27||%20_type==%27tables%27||%20_type==%27page%27||_type==%27home%27||_type==%27creature%27%20||%20_type==%27spell%27%20]{%22slug%22:slug.current,%22type%22:_type,%20%22name%22:name,%22flavorText%22:flavorText{%22res%22:children[].text}[],%20description,%20header,%20flavor_text{%22res%22:children[].text}[],%20spell_flavor_text,%20summary_flavor_text%20,%20effect%20,%20spells%20,%20lore%20,%20other%20,%20special_feat%20,%20modifiers%20,%20details%20,%20data%20,%20title%20,%20speed%20,%20reach%20,%20requirements%20}"
    )
    const result = await res.json()
    // console.log(result?.result, "result")
    setSiteData(result?.result)
  }

  async function searchSite() {
    if (word?.length > 0) {
      let withOutHeading: any = []
      let withHeading: any = []
      const test = [word]
      await siteData?.map((match: any, i) => {
        const headCheck = JSON.stringify(match)
          .toLowerCase()
          ?.includes(JSON.stringify(test).toLowerCase())
        const wordCheck = JSON.stringify(match)
          .toLowerCase()
          ?.includes(word?.toLowerCase())
        if (match?.name !== "Glossary") {
          if (headCheck) {
            withHeading.push({ headingCheck: true, ...match })
          } else if (wordCheck) {
            withOutHeading.push({ headingCheck: false, ...match })
          }
        }
      })

      // const words = siteData.filter((matches: any, i) => {
      //   const test = [word]
      //   console.log(test, "test")
      //   // console.log(JSON.stringify(matches)?.includes(`${test}`))
      //   return JSON.stringify(matches)?.includes(`${test}`)
      //   // JSON.stringify(matches)
      //   //   .toLowerCase()
      //   //   ?.includes(word?.toLowerCase()) && matches?.name !== "Glossary"
      // })
      await withOutHeading?.sort(
        (a: any, b: any) =>
          JSON.stringify(b)
            ?.toLowerCase()
            ?.match(new RegExp(word.toLowerCase(), "g"))?.length! -
          JSON.stringify(a)
            ?.toLowerCase()
            ?.match(new RegExp(word.toLowerCase(), "g"))?.length!
      )
      let words: any = [...withHeading, ...withOutHeading]

      // // Sort the results based on whether the name or page route matches the search term
      // words.sort((a: any, b: any) => {
      //   const isNameMatchA = a?.name?.toLowerCase() === word.toLowerCase()
      //   const isPageRouteMatchA = `/${a?.slug}` === a?.slug

      //   const isNameMatchB = b?.name?.toLowerCase() === word.toLowerCase()
      //   const isPageRouteMatchB = `/${b?.slug}` === b?.slug

      //   // Sort in descending order, moving the matching result to the top
      //   if (isNameMatchA || isPageRouteMatchA) return -1
      //   if (isNameMatchB || isPageRouteMatchB) return 1

      //   return 0
      // })

      await setFilteredData(words)
    }
  }

  useEffect(() => {
    if (siteData?.length > 0) {
      const searchTerm = decodeURI(window.location.href.split("query=")[1])
      setWord(searchTerm)
      searchSite()
    }
  }, [word, siteData, location])

  function filterSlug(slug) {
    const filteredSlug = creatureData?.filter(detail => {
      return detail?.slug?.current === slug
    })
    return filteredSlug
  }

  function filteredSpellSlug(slug) {
    // const filteredSlug = spellSlugData?.filter(detail => {
    //   // console.log(detail?.slug?.current, slug)
    //   return detail?.slug?.current === slug
    // })
    const filteredSlug = JSON.stringify(spellSlugData).includes(slug)
    // console.log(filteredSlug, "filtered slug")
    return filteredSlug
  }

  function filterClassSlug(slug) {
    // console.log(classSpells, "class spells", slug)
    const filteredClassSlug = classSpells?.filter(spell => {
      return JSON.stringify(spell).includes(slug)
    })
    // console.log(filteredClassSlug, "filteredClassSlug")
    return filteredClassSlug
  }

  function routePath(details) {
    switch (details?.type) {
      case "herospells":
        return renderSearchContent(details, `/hero-spells`)
      case "denizen":
        return renderSearchContent(details, `/denizens/${details?.slug}`)
      case "class":
        return renderSearchContent(details, `/classes/${details?.slug}`)
      case "page":
        return renderSearchContent(details, `/pages/${details?.slug}`)
      case "home":
        return renderSearchContent(details, "/")
      case "creature":
        const creatureSlug = filterSlug(details?.slug)
        return renderSearchContent(
          details,
          `/denizens/${creatureSlug[0]?.type[0]?.slug?.current}`
        )
      case "spell":
        // console.log(details, "detail")
        const slug = filteredSpellSlug(details?.slug)
        const classSlug = filterClassSlug(details?.slug)
        // console.log(classSlug, "classSlug")
        // console.log(slug, "slug")
        if (slug) {
          return renderSearchContent(details, `/hero-spells`)
        }
        if (classSlug) {
          return renderSearchContent(
            details,
            `/classes/${classSlug[0]?.node?.slug?.current}`
          )
        }
      case "tables":
        return renderSearchContent(
          { ...details, name: "Characteristics" },
          "/pages/characteristics"
        )
      default:
        return <p style={{ padding: "10px" }}>No results found</p>
    }
  }

  function renderSearchContent(details, slug) {
    const data = JSON.stringify(
      details?.flavorText ||
        details.flavor_text ||
        details?.description ||
        details?.summary_flavor_text ||
        details?.spell_flavor_text ||
        details?.effect ||
        details?.spells ||
        details?.lore ||
        details?.other ||
        details?.special_feat ||
        details?.modifiers ||
        details?.details ||
        details?.data ||
        details?.title ||
        details?.reach ||
        details?.speed ||
        details?.requirements
    )
    var stringLiterals = new RegExp(word, "gi")
    var count = (data.match(stringLiterals) || []).length

    // Check if the result's name or page route matches the search term
    // const isNameMatch = details?.name?.toLowerCase() === word.toLowerCase()
    // const isPageRouteMatch = `/${details?.slug}` === slug

    // Apply additional styling if there is a match
    const resultStyle = {
      cursor: "pointer",
      textDecoration: details?.headingCheck ? "underline" : "none",
    }

    return (
      <div className="py-3">
        <div onClick={() => navigate(slug)}>
          <a className="search-slug" style={resultStyle}>
            {details?.name || "Home"} ({count === 0 ? 1 : count})
          </a>
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <div className="bg-texture px-6 lg:px-0 overflow-hidden borderImageFull">
        <div className="mt-12 mx-auto">
          <h1
            className="text-center text-mocha font-display text-5xl"
            style={{ fontWeight: 700 }}
          >
            Results
          </h1>
        </div>
        <div className="max-w-4xl mx-auto sm:px-0 bg-title">
          <div className="font-body text-fontSize body max-w-none mx-auto prose leading-relaxed font-medium">
            {filteredData?.length > 0 &&
              filteredData?.map((data: any, i) => {
                return <div key={i}>{routePath(data)}</div>
              })}
            {siteData?.length > 0 && filteredData?.length === 0 && (
              <p style={{ padding: "10px" }}>No results found</p>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage

export const query = graphql`
  query {
    allSanityCreature {
      nodes {
        type {
          slug {
            current
          }
        }
        name
        slug {
          current
        }
      }
    }
    allSanityHerospells {
      edges {
        node {
          combatspells {
            name
            slug {
              current
            }
          }
          arcanaspells {
            name
            slug {
              current
            }
          }
          rangedspells {
            name
            slug {
              current
            }
          }
          skillspells {
            name
            slug {
              current
            }
          }
        }
      }
    }
    allSanityClass {
      edges {
        node {
          spells {
            name
            slug {
              current
            }
          }
          name
          slug {
            current
          }
        }
      }
    }
  }
`
